import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { END } from "redux-saga";

import CMSPageHead from "../../components/common/cms/cms-page-head";
import DesktopMicroSite from "../../components/common/desktop-microsite";

import { errorInMicroSitePage, requestCMSMicroSitePageData } from "../../src/actions/cms-microSite-actions";
import { FnpPageType, setFnpPageType } from "../../src/utils/common";
import wrapper from "../../redux/store/store";
import useCommonOmnitureSetup from "../../components/hooks/analytics/useCommonOmnitureSetup";
import { useSetCurrencyDetailsToWindow, useReloadWidgetsPincodeChange } from "../../src/utils/hooks";
import { requestWidgetHelperData } from "../../src/actions/common-page-actions";
import useClevertapSetup from "../../components/hooks/analytics/clevertap/useCleverTapSetup";

/**
 * This method brings in all data which has to be displayed on the info page
 *
 * @returns {React.ReactElement} jsx for the info page.
 */
const MicroSitePages = () => {
  useSetCurrencyDetailsToWindow();
  useReloadWidgetsPincodeChange();
  const { breadcrumbs } = useSelector((state) => state.commonPageData);
  const { data: microSitePageData } = useSelector((state) => state.cmsMicroPage);

  const microSiteData = {
    microSite: microSitePageData,
    pageType: FnpPageType.INFO,
    breadcrumbs,
  };

  useEffect(() => {
    setFnpPageType(FnpPageType.INFO);
  }, []);

  useCommonOmnitureSetup();
  useClevertapSetup();

  return (
    <>
      <CMSPageHead />
      <DesktopMicroSite microSiteData={microSiteData} />
    </>
  );
};

MicroSitePages.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx) => {
  const isServer = typeof window === "undefined";
  // for getting trace id serverside logs in kibana
  if (isServer && global) {
    global.pageContext = ctx;
  }
  let [pathParam] = ctx.asPath.split("?");
  if (pathParam.charAt(0) === "/") {
    pathParam = pathParam.substring(1);
  }

  if (ctx.req) {
    try {
      const contentOptions = {
        contentURL: pathParam,
        headers: ctx?.req.headers,
        ctx,
        setResHeaders: true,
      };

      await store.dispatch(requestWidgetHelperData());
      await store.dispatch(requestCMSMicroSitePageData(contentOptions));
    } catch (error) {
      store.dispatch(errorInMicroSitePage(error.message));
    }

    // Stop the saga if on server
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }

  return {};
});

export default MicroSitePages;
